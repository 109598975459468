import React, { useState, useEffect, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import Modal from 'react-modal'
import styled from 'styled-components'
import qs from 'qs'

import SEO from '../components/SEO'
import FullWidthWrapper from '../components/FullWidthWrapper'
import ForegroundLayout from '../components/ForegroundLayout'
import MediumHeading from '../components/MediumHeading'
import AngledDivider from '../components/AngledDivider'
import ContentBlock from '../components/ContentBlock'
import Overlay from '../components/Overlay'
import FlexBox from '../components/FlexBox'
import WordArt from '../components/WordArt'
import Paragraph from '../components/Paragraph'

const voteOptions = [
  { label: 'Bella Notte', value: 'Bella Notte' },
  { label: 'Pizzeria Davide', value: 'Pizzeria Davide' },
  { label: 'Pizza Planet', value: 'Pizza Planet' },
  {
    label: 'Proper Brick Oven & Tap Room',
    value: 'Proper Brick Oven & Tap Room'
  },
  {
    label: 'Other...',
    value: '',
    allowCustomInput: true
  }
]

const HelloSerendipity = ({ data }) => (
  <React.Fragment>
    <SEO title="Hello, Serendipity!" />
    <FormWrapper backgroundImage={data.heroImage.childImageSharp.fluid}>
      <HeroBox>
        <Heading />
        <ForegroundLayout useNaturalHeight>
          <FormContainer />
        </ForegroundLayout>
      </HeroBox>
    </FormWrapper>
    <WeAre />
    <Legal />
  </React.Fragment>
)

const FormWrapper = ({ backgroundImage, children }) => (
  <FullWidthWrapper minHeight="100vh">
    <Image
      fluid={backgroundImage}
      alt=""
      css={`
        height: 150vh;
        min-height: 100vh;
        @media (min-width: 1001px) and (max-height: 1000px) {
          height: 150vh;
        }
        @media (max-width: 1000px) and (max-height: 1000px) {
          height: 150vh;
        }
        @media (max-height: 700px) {
          height: 175vh;
        }
        @media (max-height: 500px) {
          height: 200vh;
        }
      `}
    />
    <Overlay alpha={0.4} />
    {children}
  </FullWidthWrapper>
)

const HeroBox = ({ children }) => (
  <FlexBox
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    itemSpacing="10vh"
    css={`
      padding: 25vh 0;
      position: absolute;
      top: 0;
    `}
    useFlexHeight
  >
    {children}
  </FlexBox>
)

const WeAre = () => (
  <React.Fragment>
    <AngledDivider color="#1b1b1b" position="top" />
    <ContentBlock backgroundColor="#1b1b1b">
      <MediumHeading>We Are Orange Astronaut</MediumHeading>
      <Paragraph>
        Orange Astronaut is a full-service creative agency that specializes in
        advertisement, marketing and digital intelligence services. We’re data
        driven creators, developers and marketers who understand the science
        behind brand growth. We combine strategic marketing with remarkable
        cinematography and new technologies to grow your brand.
      </Paragraph>
    </ContentBlock>
    <AngledDivider color="#1b1b1b" position="bottom" />
  </React.Fragment>
)

const Legal = () => (
  <FullWidthWrapper
    css={`
      margin-top: 10vw;
    `}
  >
    <ForegroundLayout
      css={`
        padding: 2rem 0;
      `}
    >
      <SmallPrint>
        {legalLinks.map((link, index) => (
          <StyledLink key={index} to={link.url}>
            {link.title}
          </StyledLink>
        ))}
        <Copyright>
          © 2019 Orange Astronaut, LLC. All rights reserved.
        </Copyright>
      </SmallPrint>
    </ForegroundLayout>
  </FullWidthWrapper>
)

const Heading = () => (
  <WordArt.Wrapper
    css={`
      max-width: 80vw;
      text-align: center;
      height: unset;
    `}
  >
    <WordArt.Line color="#fff" fontSize="0.8em">
      So tell us...
    </WordArt.Line>
    <WordArt.Line color="#fff" fontSize="0.4em" lineHeight="2">
      Where should we order pizza from?
    </WordArt.Line>
  </WordArt.Wrapper>
)

const FormContainer = () => {
  const initialFormData = {
    vote: '',
    customVote: '',
    name: '',
    email: '',
    company: ''
  }

  const [formData, setFormData] = useState(initialFormData)
  const [canSubmit, setCanSubmit] = useState(false)
  const [didSubmit, setDidSubmit] = useState(false)
  const [showThankYouModal, setShowThankYouModal] = useState(false)
  const emailInputElement = useRef(null)
  useEffect(() => {
    const isFormDataValid = () => {
      const isVoteValid = formData.vote !== ''
      const isNameValid = formData.name !== ''
      const isEmailValid = emailInputElement.current.validity.valid
      const isCompanyValid = true // let individuals submit
      return isVoteValid && isNameValid && isEmailValid && isCompanyValid
    }
    setCanSubmit(isFormDataValid())
  }, [formData])

  const onChange = (event, isCustomOption) => {
    if (isCustomOption) {
      setFormData({
        ...formData,
        customVote: event.target.value,
        vote: event.target.value
      })
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value
      })
    }
  }
  const onSubmit = event => {
    setCanSubmit(false)
    event.preventDefault()
    if (typeof window !== 'undefined') {
      const url =
        'https://script.google.com/macros/s/AKfycbxMeQEmsWitctV3y--CyFVZrWvMQ0y8p5Su4w5g8LbBWSIqsHs1/exec'
      const config = {
        method: 'POST',
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        referrer: 'no-referrer',
        body: qs.stringify(formData, { arrayFormat: 'comma' }) // best format for google scripts
      }
      window
        .fetch(url, config)
        .then(response => response.json())
        .then(() => setDidSubmit(true))
      setTimeout(() => setShowThankYouModal(true), 800)
    }
  }
  return (
    <React.Fragment>
      <Form
        data={formData}
        onChange={onChange}
        onChangeCustomOption={event => onChange(event, true)}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        didSubmit={didSubmit}
        emailInputElementRef={emailInputElement}
      />
      <ThankYouModal
        isOpen={showThankYouModal}
        onRequestClose={() => setShowThankYouModal(false)}
      />
    </React.Fragment>
  )
}

const Form = ({
  data,
  onChange,
  onChangeCustomOption,
  canSubmit,
  onSubmit,
  didSubmit,
  emailInputElementRef
}) => (
  <FlexBox
    as="form"
    id="pizza-form"
    flexDirection="column"
    alignItems="center"
    itemSpacing="3rem"
    useFlexHeight
    isResponsive
    onSubmit={onSubmit}
    css={`
      margin: auto;
      max-width: 600px;
    `}
  >
    <FlexBox as={StyledFieldset} flexDirection="column" itemSpacing="1rem">
      {voteOptions.map((option, index) => (
        <React.Fragment key={index}>
          {option.allowCustomInput ? (
            <CustomRadioInput
              id={`vote-${index}`}
              name="vote"
              label={option.label}
              value={data.customVote}
              checked={data.vote === data.customVote}
              onChange={event => onChangeCustomOption(event)}
            />
          ) : (
            <RadioInput
              id={`vote-${index}`}
              name="vote"
              label={option.label}
              value={option.value}
              checked={data.vote === option.value}
              onChange={event => onChange(event)}
            />
          )}
        </React.Fragment>
      ))}
    </FlexBox>
    <FlexBox as={StyledFieldset} flexDirection="column" itemSpacing="1rem">
      <TextInput
        name="name"
        required
        aria-label="name"
        placeholder="Name"
        value={data.name}
        onChange={event => onChange(event)}
      />
      <EmailInput
        ref={emailInputElementRef}
        type="email"
        name="email"
        required
        aria-label="email"
        placeholder="Email"
        value={data.email}
        onChange={event => onChange(event)}
      />
      <TextInput
        name="company"
        aria-label="company"
        placeholder="Company"
        value={data.company}
        onChange={event => onChange(event)}
      />
    </FlexBox>
    <SubmitButton disabled={!canSubmit || didSubmit}>Let's Eat</SubmitButton>
  </FlexBox>
)

const StyledFieldset = styled('fieldset')`
  border: 0;
`

const TextInput = styled('input').attrs(props => ({ type: 'text' }))`
  font-size: 18px;
  font-weight: 300;
  padding: 0 25px;
  min-width: 250px;
  height: 50px;
  border: 0;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  transition: background-color 100ms linear;
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.9);
  }
  &::placeholder {
    color: #bbb;
    opacity: 1;
  }
  @media (min-width: 1200px) {
    font-size: 22px;
  }
`

const EmailInput = styled(TextInput).attrs(props => ({ type: 'email' }))``

const CustomRadioInput = ({ id, name, label, value, checked, onChange }) => (
  <FlexBox flexDirection="row" itemSpacing="1rem">
    <StyledRadioButton
      id={id}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <TextInput
      name={name}
      aria-label="custom option"
      placeholder={label}
      value={value}
      onChange={onChange}
      onFocus={onChange}
      css={`
        flex: auto;
      `}
    />
  </FlexBox>
)

const RadioInput = ({ id, name, label, value, checked, onChange }) => (
  <FlexBox flexDirection="row" itemSpacing="1rem">
    <StyledRadioButton
      id={id}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <StyledRadioLabel htmlFor={id}>{label}</StyledRadioLabel>
  </FlexBox>
)

const StyledRadioButton = styled('input').attrs(props => ({ type: 'radio' }))`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #bbb;
  transition: 0.2s all linear;
  margin-right: 5px;
  position: relative;
  top: 4px;
  &:checked {
    border: 6px solid #fff;
  }
`

const StyledRadioLabel = styled('label')`
  font-size: 18px;
  color: #fff;
  @media (min-width: 1200px) {
    font-size: 22px;
  }
`

const SubmitButton = styled.button`
  position: relative;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  flex: none;
  padding: 0 20px;
  height: 50px;
  border-radius: 25px;
  border: 0;
  background-color: ${props => (props.disabled ? '#666' : '#f93616')};
  color: ${props => (props.disabled ? '#ccc' : '#fff')};
  font-size: 20px;
  font-family: 'Prompt', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform 100ms ease-out;
  &:hover {
    transform: ${props => (props.disabled ? 'none' : 'scale(1.05)')};
  }
`

const SmallPrint = styled(props => (
  <FlexBox flexDirection="row" itemSpacing="1rem" isResponsive {...props} />
))`
  & > * {
    display: inline-block;
  }
  & a {
    font-size: 16px;
  }
`

const StyledLink = styled(Link)`
  color: #888;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: color 150ms linear;
  &:hover {
    color: #ddd;
  }
`

const Copyright = styled.p`
  display: inline-block;
  margin: 0;
  color: #888;
  font-weight: 400;
`

const legalLinks = [
  {
    title: 'Terms of Use',
    url: '/terms'
  },
  {
    title: 'Privacy Policy',
    url: '/privacy'
  }
]

const ThankYouModal = ({ isOpen, onRequestClose }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
    >
      <MediumHeading
        css={`
          text-align: center;
        `}
      >
        Your vote has been cast!
      </MediumHeading>
      <Paragraph>
        Thanks for your submission. We&#39;re looking forward to meeting you!
      </Paragraph>
    </StyledModal>
  )
}

function ModalAdapter({ children, className, ...props }) {
  return (
    <Modal
      overlayClassName={className}
      className={`${className}__content`}
      {...props}
    >
      {children}
    </Modal>
  )
}

const StyledModal = styled(ModalAdapter)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    position: relative;
    padding: 2rem;
    color: #fff;
    background-color: #2c2c2c;
    outline: none;
  }
`

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "space.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5000, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default HelloSerendipity
